import Vue from 'vue'
import VueRouter from 'vue-router'




Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main.vue'),
 
    redirect: '/main/indexrole',
    children: [
      {
        path: 'indexrole',
        name: 'IndexRole',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/index/indexRole.vue')

      },

      {
        path: 'role',
        name: 'Role',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/role/Role.vue')
      },
      {
        path: 'userinfo',
        name: 'Userinfo',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/info/Userinfo.vue')
      },
      {
        path: 'enterpriseinfo',
        name: 'Enterpriseinfo',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/info/Enterpriseinfo.vue')
      },

      // 赛事管理
      //分别是 添加赛事 赛事信息管理 报名管理
      {
        path: 'createsaishi',
        name: 'CreateSaishi',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/saishi/CreateSaishi.vue')
      },
      {
        path: 'saishifankui',
        name: 'Saishifankui',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/saishi/Saishifankui.vue')
      },
      {
        path: 'saishishow',
        name: 'SaishiShow',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/saishi/SaishiShow.vue')
      },
      {
        path: 'baomingwithscore',
        name: 'BaomingwithScore',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/saishi/BaomingwithScore.vue')
      },



      {
        path: 'essay',
        name: 'Essay',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/essay/Essay.vue')
      },
      {
        path: 'content',
        name: 'Content',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/essay/Content.vue')
      },

      {
        path: 'person',
        name: 'Person',
        meta: {
          requireAuth: true
        },
        component: () => import('../pages/person/Person.vue')
      }

    ]
  },


  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/Register.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/IndexMain.vue'),
    redirect: '/index/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../pages/home/Home.vue')
      },
      {
        path: 'saishi',
        name: 'Saishi',
        component: () => import('../pages/home/Saishi.vue'),

      },

      //赛事详情
      {
        path: 'detailsaishi',
        name: 'DetailSaishi',
        component: () => import('../components/saishi/detailsaishi/detailsaishi.vue')
      },

      {
        path: 'essay',
        name: 'Essay',
        component: () => import('../pages/home/Essay.vue')

      },


      //文章详情
      {
        path: 'detailessay',
        name: 'DetailEssay',
        component: () => import('../components/essay/detailessay/detailessay.vue')
      },
      {

        path: 'person',
        name: 'Person',
        component: () => import('../pages/home/Person.vue')

      },
      {
        path: 'yibaoming',
        name: 'Yibaoming',
        component: () => import('../pages/home/Yibaoming.vue')
      },

      // {
      //   path: 'test',
      //   name: 'test',
      //   component: () => import('../pages/test/test.vue'),
      //   redirect: '/index/test/person',
      //   children: [
      //     {

      //       path: 'person',
      //       name: 'Person',
      //       component: () => import('../pages/home/persons/Person.vue')

      //     },
      //     {
      //       path: 'yibaoming',
      //       name: 'Yibaoming',
      //       component: () => import('../pages/home/yibaoming/Yibaoming.vue')
      //     }
      //   ]
      // }


    ]
  }
  
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next)=>{
  if(to.meta.requireAuth){
    if(sessionStorage.getItem("rid")==null || sessionStorage.getItem("rid")=='' || sessionStorage.getItem("rid")==undefined)
      {
      next({
        path:'/'
      })
      alert("管理员和企业请先登录")
    }
    else {
      next()
    }
  }else{
    next()
  }
})

export default router
